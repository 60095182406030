import cn from 'classnames';
import { m, useInView } from 'framer-motion';
import React, { ReactNode, useMemo, useRef } from 'react';
import Typewriter from 'typewriter-effect';

import leftTitle from '@/assets/images/home/left_title.png';
import rightTitle from '@/assets/images/home/right_title.png';
interface BlockLayoutProps {
  className?: string;
  children: ReactNode;
  type:
    | 'about'
    | 'schedule'
    | 'guests'
    | 'sponsor'
    | 'committee'
    | 'community'
    | 'media';
}

export function BlockLayout(props: BlockLayoutProps) {
  const { className, children, type } = props;
  const ref = useRef<HTMLDivElement>(null);
  const isInView = useInView(ref, { once: true });
  const title = useMemo(() => {
    switch (type) {
      case 'about':
        return 'About CKS Crypto Bootcamp';
      case 'schedule':
        return 'Schedule';
      case 'guests':
        return 'Guests';
      case 'committee':
        return 'Community Organizing Committee';
      case 'community':
        return 'Community Support';
      case 'media':
        return 'Media Partner';
      case 'sponsor':
        return 'Sponsor';
      default:
        break;
    }
  }, [type]);

  return (
    <m.div
      initial={{
        translateY: '120px'
      }}
      whileInView={{
        translateY: 0
      }}
      viewport={{ once: true }}
      transition={{
        duration: 1,
        type: 'spring'
      }}
      className={cn(
        className,
        'relative mx-auto box-border w-[90vw] max-w-[1200px] rounded-[1.04vw] border-[2px] border-solid border-white'
      )}
    >
      {children}
      <div
        ref={ref}
        className="absolute left-1/2 top-0 flex aspect-[6.06] w-[300px] -translate-x-1/2 -translate-y-1/2 sm:w-[600px] lg:w-[703px]"
      >
        <img src={leftTitle} className="h-full w-auto" />
        <div className='-mx-[2px] flex flex-1 items-center justify-center bg-[url("/title_bg.png")] bg-contain bg-repeat-x text-center text-base font-bold leading-[1.2] sm:text-3xl lg:text-[36px]'>
          {title && isInView && (
            <Typewriter
              onInit={(typewriter) => {
                typewriter.typeString(title || '').start();
              }}
              options={{
                cursor: '',
                delay: ['committee', 'about', 'community', 'media'].includes(
                  type
                )
                  ? 60
                  : 'natural'
              }}
            />
          )}
        </div>
        <img src={rightTitle} className="h-full w-auto" />
      </div>
    </m.div>
  );
}
