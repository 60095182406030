import cn from 'classnames';
import React, { useRef, useState } from 'react';
import { useClickAway } from 'react-use';

import LogoSVG from '@/assets/images/common/logo.inline.svg';
import MenuSVG from '@/assets/images/common/menu.inline.svg';
interface HeaderProps {
  className?: string;
}

const menus = [
  {
    title: 'About',
    link: 'about'
  },
  {
    title: 'Schedule',
    link: 'schedule'
  },
  {
    title: 'Guests',
    link: 'guests'
  },
  {
    title: 'Partnerships',
    link: 'partners'
  }
];

export function Header(props: HeaderProps) {
  const { className } = props;
  const ref = useRef<HTMLDivElement>(null);
  const [showMenus, setShowMenus] = useState(false);
  useClickAway(ref, () => {
    setShowMenus(false);
  });

  return (
    <header
      className={cn(
        'h-15 sticky top-0 z-20 flex items-center  bg-[linear-gradient(0deg,#29A4D0_0%,#ADE9FF_100%)] py-[6px] px-4 sm:h-[76px] sm:bg-[linear-gradient(180deg,#081C1F_-31.77%,#081C1F_132.81%)]',
        className
      )}
    >
      <ul className="mx-auto hidden items-center gap-x-7 sm:flex">
        {menus.map((menu) => (
          <li
            key={menu.title}
            className="cursor-pointer text-lg font-medium leading-[1.65] 3xl:text-xl"
            onClick={() => {
              const ele = document.getElementById(menu.link);
              if (ele) {
                ele.scrollIntoView({
                  behavior: 'smooth'
                });
              }
            }}
          >
            {menu.title}
          </li>
        ))}
      </ul>
      <div className="flex w-full items-center justify-between sm:hidden">
        <LogoSVG width={36} />
        <div
          className="relative"
          ref={ref}
          onClick={() => {
            setShowMenus(!showMenus);
          }}
        >
          <MenuSVG width={36} />
          <div
            className={cn(
              'absolute top-[52px] right-0 w-40 overflow-hidden rounded-lg bg-[#B2DFF0] transition-[height] duration-200',
              showMenus ? 'h-40' : 'h-0'
            )}
          >
            {menus.map((menu, index) => {
              const isLast = index === menus.length - 1;
              return (
                <div
                  key={menu.title}
                  onClick={() => {
                    const ele = document.getElementById(menu.link);
                    if (ele) {
                      ele.scrollIntoView({
                        behavior: 'smooth'
                      });
                    }
                  }}
                  className={cn(
                    'flex h-10 items-center px-4 text-[13px] font-medium text-[#142E34]',
                    !isLast && 'border-b-[1px] border-solid border-[#A3D3E5]'
                  )}
                >
                  {menu.title}
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </header>
  );
}
