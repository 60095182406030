import cn from 'classnames';
import { m } from 'framer-motion';
import React from 'react';

import bottomSplashImg from '@/assets/images/home/footer/bottom_splash.png';
import CloudOneSVG from '@/assets/images/home/footer/cloud1.inline.svg';
import CloudTwoSVG from '@/assets/images/home/footer/cloud2.inline.svg';
import CloudThreeSVG from '@/assets/images/home/footer/cloud3.inline.svg';
import rightSplashImg from '@/assets/images/home/footer/right_splash.png';
import sunImg from '@/assets/images/home/footer/sun.png';
import treeImg from '@/assets/images/home/footer/tree.png';

interface SectionFooterProps {
  className?: string;
}

export function SectionFooter(props: SectionFooterProps) {
  const { className } = props;

  return (
    <div
      className={cn(
        className,
        'relative mt-4 aspect-[8.29] w-full sm:mt-[125px]'
      )}
    >
      <m.div
        initial={{
          translateX: '50%',
          opacity: 0
        }}
        whileInView={{
          translateX: 0,
          opacity: 1
        }}
        transition={{
          type: 'spring',
          duration: 2,
          delay: 0.5
        }}
        viewport={{ once: true }}
        className="absolute right-0 bottom-0 hidden w-[20%] sm:block"
      >
        <img src={rightSplashImg} className="w-full" />
        <img src={sunImg} className="absolute -right-0 -top-[18%] w-[42%]" />
      </m.div>
      <m.img src={bottomSplashImg} className="absolute inset-0 z-10 w-full" />
      <m.img
        initial={{
          translateX: '-100%',
          opacity: 0
        }}
        whileInView={{
          translateX: '-10%',
          opacity: 1
        }}
        transition={{
          type: 'spring',
          duration: 2
        }}
        src={treeImg}
        viewport={{ once: true }}
        className="absolute left-0 bottom-[15vw] hidden w-[10%] sm:block"
      />
      <m.div
        initial={{
          translateX: '0px'
        }}
        animate={{ translateX: '40px' }}
        transition={{
          duration: 4,
          repeat: Infinity,
          repeatType: 'reverse',
          delay: 2
        }}
        className="absolute left-[13.33vw] bottom-[12.29vw] hidden sm:block"
      >
        <CloudOneSVG className="w-[7.08vw]" />
      </m.div>
      <m.div
        initial={{
          translateX: '0px'
        }}
        animate={{ translateX: '-40px' }}
        transition={{
          duration: 5,
          repeat: Infinity,
          repeatType: 'reverse',
          delay: 2
        }}
        className="absolute left-[38vw] bottom-[7.45vw] hidden sm:block"
      >
        <CloudTwoSVG className="w-[7.24vw]" />
      </m.div>
      <m.div
        initial={{
          translateX: '0px'
        }}
        animate={{ translateX: '-80px' }}
        transition={{
          duration: 6,
          repeat: Infinity,
          repeatType: 'reverse',
          delay: 2
        }}
        className="absolute right-[13.33vw] bottom-[13vw] hidden sm:block"
      >
        <CloudThreeSVG className="w-[8.9vw]" />
      </m.div>
    </div>
  );
}
