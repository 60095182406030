import cn from 'classnames';
import dayjs from 'dayjs';
import { m } from 'framer-motion';
import React, { useMemo, useState } from 'react';

import CloudRightSVG from '@/assets/images/home/cloud_big_right.inline.svg';
import CloudLeftSVG from '@/assets/images/home/cloud_small_left.inline.svg';
import LeftTopBorderSVG from '@/assets/images/home/schedule/border_left_top.inline.svg';
import RightBottomBorderSVG from '@/assets/images/home/schedule/border_right_bottom.inline.svg';
import { BlockLayout } from '@/components/common/BlockLayout';

import { TimeLine } from './TimeLine';
interface SectionScheduleProps {
  className?: string;
}

export interface IScheduleTimeLine {
  time: string;
  type:
    | 'opening'
    | 'lunch'
    | 'break'
    | 'announcement'
    | 'coding'
    | 'award'
    | 'course'
    | 'normal';
  devCourse?: {
    title: string;
    color: string;
    bgColor: string;
  };
  topic: string;
  speakers?: Array<{
    name?: string;
    desc?: string;
  }>;
}
interface ISchedule {
  date: string;
  totalTime: string;
  title: string;
  timelines: IScheduleTimeLine[];
}
const schedules: ISchedule[] = [
  {
    date: '20240126',
    totalTime: '10:00-18:15',
    title: 'Blockchain Development Bootcamp',
    timelines: [
      {
        time: '10:00 - 10:30',
        type: 'opening',
        topic: 'Opening Speech',
        speakers: [
          {
            desc: 'Waseda Universities Representative'
          },
          {
            desc: 'ChainIDE'
          },
          {
            desc: 'KEKKAI'
          },
          {
            desc: 'Soulink'
          }
        ]
      },
      // {
      //   time: '10:30 - 12:00',
      //   type: 'course',
      //   devCourse: {
      //     title: 'Basic Development Course 01',
      //     color: '#391902',
      //     bgColor: '#FDD9BF'
      //   },
      //   topic: 'Introduction to Blockchain Basics: A Developer Perspective',
      //   speakers: [
      //     {
      //       name: 'XiaoWu',
      //       desc: 'Founder of ChainIDE'
      //     }
      //   ]
      // },
      {
        time: '10:30 - 11:15',
        type: 'course',
        topic: 'Introduction to Blockchain Basics: A Developer Perspective',
        speakers: [
          {
            name: 'XiaoWu',
            desc: 'Founder of ChainIDE'
          }
        ]
      },
      {
        time: '11:15 - 12:00',
        type: 'course',
        topic: 'Why Solana?',
        speakers: [
          {
            name: 'Dean',
            desc: ' Dev Rel of Solana'
          }
        ]
      },
      {
        time: '12:00 - 13:00',
        type: 'lunch',
        topic: 'Lunch Break'
      },
      {
        time: '13:00 - 13:45',
        type: 'course',
        topic: 'Why Sui?',
        speakers: [
          {
            name: 'Cheyenne Sanjita',
            desc: 'DevRel @ Sui Foundation'
          }
        ]
      },
      {
        time: '13:45 - 14:30',
        type: 'course',
        topic: 'Full Stack Decentralization with Internet Computer Protocol',
        speakers: [
          {
            name: 'Paul Liu',
            desc: 'Staff Engineer of DEINITY Foundation'
          }
        ]
      },
      {
        time: '14:30 - 14:45',
        type: 'break',
        topic: 'Break'
      },
      {
        time: '14:45 - 15:15',
        type: 'course',
        topic: 'My Startup Journey and the Web3 Innovation',
        speakers: [
          {
            name: 'Suji',
            desc: 'Founder of Mask Network'
          }
        ]
      },
      {
        time: '15:15 - 15:45',
        type: 'course',
        topic: 'Your Web3 Future Powered by BNB Chain',
        speakers: [
          {
            // name: '--',
            desc: 'BNB CHAIN'
          }
        ]
      },
      {
        time: '14:45 - 16:00',
        type: 'break',
        topic: 'Break'
      },
      {
        time: '16:00 - 16:45',
        type: 'course',
        topic: 'Your Web3 Future Powered by BNB Chain',
        speakers: [
          {
            name: 'Walter Lee',
            desc: 'BNB Chain'
          }
        ]
      },
      {
        time: '16:45 - 17:15',
        type: 'course',
        topic: 'Fundamental Steps of Building a Dapp',
        speakers: [
          {
            name: 'William xu, Zan Engineer ',
            desc: 'ZAN.top Developer'
          }
        ]
      }
    ]
  },
  {
    date: '20240127',
    totalTime: '10:00-16:45',
    title: 'Blockchain Business Bootcamp',
    timelines: [
      {
        time: '10:00 - 10:30',
        type: 'opening',
        topic: 'Opening Speech',
        speakers: [
          {
            name: `主办方代表（可以merge或者任一代表）`
          },
          {
            name: `廣末紀之, ビットバンク株式会社取締役社長 JCBA会長`
          },
          {
            name: `早稻田大学代表`
          }
        ]
      },
      {
        time: '10:30 - 11:00',
        type: 'course',
        topic:
          '日本発のグローバルブロックチェーン ~日本企業のWeb3進出のベストバートナー~',
        speakers: [
          {
            name: `Yuhong`,
            desc: 'Leader of Astar Japan'
          }
        ]
      },
      {
        time: '11:00 - 11:30',
        type: 'course',
        topic: 'Data Research Engineering',
        speakers: [
          {
            name: `Elena Kang`,
            desc: 'Business Strategy Director of Presto'
          }
        ]
      },
      {
        time: '11:30 - 12:00',
        type: 'course',
        topic: 'Crypto,Blockchain and beyond',
        speakers: [
          {
            name: `Dohyun Pak`,
            desc: 'CEO of Bifrost'
          }
        ]
      },
      {
        time: '12:00 - 12:30',
        type: 'course',
        topic: 'Web3ビジネスを取り卷く国内環境',
        speakers: [
          {
            name: `八角大輔`,
            desc: 'OKCoinJapan'
          }
        ]
      },
      {
        time: '12:30 - 13:30',
        type: 'lunch',
        topic: 'Lunch Break'
      },
      {
        time: '13:30 - 14:00',
        type: 'course',
        topic: 'Kanalabs',
        speakers: [
          {
            name: `Surbhi Singh / Co-founder of Kana labs`
            // desc: ''
          }
        ]
      },
      {
        time: '14:00 - 14:30',
        type: 'course',
        topic: '暗号資産経済圈のトレンド',
        speakers: [
          {
            name: `Yijin`,
            desc: 'Founder of Terminal, Ex-Representative Director at Amber JapanTerminal'
          }
        ]
      },
      {
        time: '14:30 - 14:45',
        type: 'break',
        topic: 'Break'
      },
      {
        time: '14:45 - 15:15',
        type: 'course',
        topic: 'CKS CRYPTO BOOT CAMP ~ENGAGINGVCs 101~',
        speakers: [
          {
            name: `Jonathan`,
            desc: 'SBI holdings'
          }
        ]
      }
    ]
  },
  {
    date: '20240128',
    totalTime: '10:00-17:30',
    title: 'Demo Day',
    timelines: [
      // {
      //   time: '10:00 - 11:30',
      //   type: 'announcement',
      //   topic: 'Announcement | Team up'
      // },
      // {
      //   time: '11:30 - 15:30',
      //   type: 'coding',
      //   topic: 'Live Coding & Deck'
      // },
      // {
      //   time: '15:30 - 17:00',
      //   type: 'normal',
      //   topic: 'Pitch',
      //   speakers: [
      //     {
      //       name: 'VIP3 cofounder'
      //       // desc: 'cofounder'
      //     }
      //   ]
      // },
      {
        time: '10:00 - 12:00',
        type: 'normal',
        topic: ' '
      },
      {
        time: '15:30 - 17:00',
        type: 'normal',
        topic: ' ',
        speakers: [
          {
            name: 'VIP3 cofounder'
            // desc: 'cofounder'
          }
        ]
      },
      {
        time: '17:00 - 17:30',
        type: 'award',
        topic: 'Award Ceremony'
      }
    ]
  }
];
export function SectionSchedule(props: SectionScheduleProps) {
  const { className } = props;
  const [activeScheduleTitle, setActiveScheduleTitle] = useState(
    schedules[0].title
  );

  const activeSchedule = useMemo(
    () => schedules.find((item) => item.title === activeScheduleTitle),
    [activeScheduleTitle]
  );

  return (
    <BlockLayout type="schedule">
      <section className={cn(className, 'px-[3.33%]')}>
        <m.div
          initial={{ translateX: '70%', opacity: 0 }}
          whileInView={{ translateX: '50%', opacity: 1 }}
          viewport={{ once: true }}
          transition={{
            duration: 1,
            delay: 1.5,
            type: 'spring'
          }}
          className="absolute right-0 bottom-[28.46%] z-10 hidden sm:block"
        >
          <CloudRightSVG className="w-[7.71vw]" />
        </m.div>
        <m.div
          initial={{ translateX: '-70%', opacity: 0 }}
          whileInView={{ translateX: '-40%', opacity: 1 }}
          viewport={{ once: true }}
          transition={{
            duration: 1,
            delay: 1.5,
            type: 'spring'
          }}
          className="absolute left-0 top-[8.18%] z-10 hidden sm:block"
        >
          <CloudLeftSVG className="w-[5.63vw]" />
        </m.div>
        <m.ul
          initial={{ translateY: '20px', opacity: 0 }}
          whileInView={{ translateY: 0, opacity: 1 }}
          viewport={{ once: true }}
          transition={{
            duration: 1,
            delay: 0.5,
            type: 'spring'
          }}
          className="mt-16 mb-8 grid grid-cols-1 gap-4 sm:mb-[68px] sm:mt-[118px] sm:grid-cols-3"
        >
          {schedules.map((schedule, index) => {
            const isActive = activeScheduleTitle === schedule.title;
            return (
              <li
                key={schedule.title}
                onClick={() => {
                  setActiveScheduleTitle(schedule.title);
                }}
                className={cn(
                  'relative cursor-pointer rounded-[10px] px-[6.67%] pb-[9px] pt-[19px]',
                  isActive ? 'bg-[#73D0CF]' : 'bg-[#73D0CF33]'
                )}
              >
                <h2
                  className={cn(
                    'mb-2 text-center text-base font-bold leading-[1.5] text-[#020A0D] sm:mb-[18px] sm:text-[26px]'
                  )}
                >
                  Day {index + 1} / {dayjs(schedule.date).format('MMM. D')}
                </h2>
                <p className="text-center text-sm font-medium leading-[1.2] text-[#020A0D] sm:text-lg">
                  {schedule.title}
                </p>
                {isActive && (
                  <LeftTopBorderSVG className="absolute left-[9px] top-2 sm:left-[13px] sm:top-[11px]" />
                )}
                {isActive && (
                  <RightBottomBorderSVG className="absolute right-[9px] bottom-2 sm:right-[13px] sm:bottom-[11px]" />
                )}
              </li>
            );
          })}
        </m.ul>
        <m.div
          initial={{ translateY: '20px', opacity: 0 }}
          whileInView={{ translateY: 0, opacity: 1 }}
          viewport={{ once: true }}
          transition={{
            duration: 1,
            delay: 1,
            type: 'spring'
          }}
          className="mb-[61px] overflow-hidden rounded-[10px] sm:rounded-2xl"
        >
          <div className="flex flex-wrap items-center justify-between gap-x-4 bg-[#73D0CF] py-2 px-[2.08vw] text-base font-medium leading-[1.5] text-[#020A0D] sm:py-5 sm:text-2xl">
            <span className="font-bold sm:font-medium">
              {dayjs(activeSchedule?.date).format('MMMM. D, YYYY')}{' '}
              {`(${activeSchedule?.totalTime || ''})`}
            </span>
            <span>{activeSchedule?.title}</span>
          </div>
          <div className="bg-[#132628] px-[2.08vw] pt-10 pb-10 sm:pb-[60px]">
            <TimeLine data={activeSchedule?.timelines || []} />
          </div>
        </m.div>
      </section>
    </BlockLayout>
  );
}
