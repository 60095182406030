import cn from 'classnames';
import { m } from 'framer-motion';
import React from 'react';

import CloudLeftSVG from '@/assets/images/home/cloud_big_left.inline.svg';
import CloudRightSVG from '@/assets/images/home/cloud_small_right.inline.svg';
import guestDeanImg from '@/assets/images/home/guests/dean.png';
import guestDennyImg from '@/assets/images/home/guests/Denny.png';
import guestDoh from '@/assets/images/home/guests/DohyunPak.png';
import guestDominicImg from '@/assets/images/home/guests/dominic.png';
import guestElenaImg from '@/assets/images/home/guests/elenakang.png';
import guestJonaImg from '@/assets/images/home/guests/jonathan.png';
import guestJYImg from '@/assets/images/home/guests/JY.png';
import guestMoreImg from '@/assets/images/home/guests/More.png';
import guestPaulLiuImg from '@/assets/images/home/guests/paulLiu.png';
import guestPicoImg from '@/assets/images/home/guests/pico.png';
import guestShayanImg from '@/assets/images/home/guests/shayan.png';
import guestSuji from '@/assets/images/home/guests/suji.png';
import guestSurbhiImg from '@/assets/images/home/guests/surbhi.png';
import guestTristan from '@/assets/images/home/guests/tristany.png';
import guestWalterLi from '@/assets/images/home/guests/walterlee.png';
import guestWillinImg from '@/assets/images/home/guests/weilianxu.png';
import guestWhiplusImg from '@/assets/images/home/guests/Whiplus.png';
import guestXiaoWuImg from '@/assets/images/home/guests/XiaoWu.png';
import guestYijinImg from '@/assets/images/home/guests/Yijin.png';
import guestYishiImg from '@/assets/images/home/guests/Yishi.png';
import guestYuhong from '@/assets/images/home/guests/yuhong.png';
import guestZhiImg from '@/assets/images/home/guests/zhi.png';
import { BlockLayout } from '@/components/common/BlockLayout';

const guests = [
  {
    avatar: guestXiaoWuImg,
    title: 'Xiao Wu (Ling)',
    desc: 'Founder of ChainIDE'
  },
  {
    avatar: guestDennyImg,
    title: 'Denny',
    desc: 'Founder of KEKKAI'
  },
  {
    avatar: guestJYImg,
    title: 'JY',
    desc: 'Founder of Soulink'
  },
  {
    avatar: guestDeanImg,
    title: 'Dean',
    desc: 'Dev Rel of Solana'
  },
  {
    avatar: guestPaulLiuImg,
    title: 'Paul Liu',
    desc: 'Staff Engineer of DFINITY Foundation'
  },
  {
    avatar: guestSuji,
    title: 'Suji',
    desc: 'Founder of Mask Network'
  },
  {
    avatar: guestShayanImg,
    title: 'Shayan Sanjideh',
    desc: 'Developer Relations Engineer at Sui Foundation'
  },
  {
    avatar: guestWalterLi,
    title: 'Walter Lee',
    desc: 'BNB Chain'
  },
  {
    avatar: guestSurbhiImg,
    title: 'Surbhi Singh',
    desc: 'Co-founder of Kana labs'
  },
  {
    avatar: guestWillinImg,
    title: 'William Xu',
    desc: 'Zan Engineer                           ZAN.top developer'
  },
  {
    avatar: guestDominicImg,
    title: 'Dominic',
    desc: 'Global Head of Business Development & Head of Korea'
  },
  {
    avatar: guestWhiplusImg,
    title: 'Whiplus',
    desc: 'Head of IVS Crypto'
  },
  {
    avatar: guestYishiImg,
    title: 'Yishi',
    desc: 'Founder of Onekey'
  },
  {
    avatar: guestYijinImg,
    title: 'Yijin',
    desc: `Founder of Terminal                  Ex-Representative Director at Amber JapanTerminal`
  },
  {
    avatar: guestElenaImg,
    title: 'Elena Kang',
    desc: 'Business Strategy Director of Presto'
  },
  {
    avatar: guestJonaImg,
    title: 'Jonathan',
    desc: 'Web3 Lead of Sbi Holdings'
  },
  {
    avatar: guestTristan,
    title: 'Tristan Yver',
    desc: 'Co-Founder of Backpack'
  },
  {
    avatar: guestZhiImg,
    title: '廣末紀之',
    desc: 'ビットバンク株式会社 代表取締役社長 JCBA会長'
  },
  {
    avatar: guestDoh,
    title: 'Dohyun Pak',
    desc: 'CEO  of Bifrost'
  },
  {
    avatar: guestPicoImg,
    title: 'Pico',
    desc: 'Cofounder of MochiMochi.AI'
  },
  {
    avatar: guestYuhong,
    title: 'Yuhong',
    desc: 'Leader of Astar Japan'
  },

  {
    avatar: guestMoreImg,
    title: 'More Income...',
    desc: ''
  }
];

interface SectionGuestsProps {
  className?: string;
}

export function SectionGuests(props: SectionGuestsProps) {
  const { className } = props;

  return (
    <BlockLayout type="guests">
      <section className={cn(className)}>
        <m.p
          initial={{ translateY: '20px', opacity: 0 }}
          whileInView={{ translateY: 0, opacity: 1 }}
          viewport={{ once: true }}
          transition={{
            duration: 1,
            delay: 0.5,
            type: 'spring'
          }}
          className="mt-[60px] mb-6 grid grid-cols-1 gap-5 px-[3.33%] sm:mb-[46px] sm:mt-[123px] sm:px-[4%] md:grid-cols-2 xl:grid-cols-3"
        >
          {guests.map((guest) => (
            <div
              key={guest.title}
              className="flex items-start gap-x-[10px] rounded-lg bg-[#113636] p-[10px]"
            >
              <img src={guest.avatar} className="w-[31.67%]" />
              <div className="flex-1 pt-[10px]">
                <h3 className="text-base font-bold leading-[1.5]">
                  {guest.title}
                </h3>
                {/* whitespace-nowrap */}
                {guest.desc && (
                  <div
                    style={{
                      wordBreak: 'break-word',
                      whiteSpace: 'pre-wrap',
                      wordWrap: 'break-word'
                    }}
                    className=" my-[10px] h-[1px] w-full  bg-[#1d4040]"
                  ></div>
                )}
                <p
                  className="  text-sm leading-[1.2] text-[#FFFFFFCC] "
                  style={{
                    wordBreak: 'break-word',
                    whiteSpace: 'pre-wrap',
                    wordWrap: 'break-word'
                  }}
                >
                  {guest.desc}
                </p>
              </div>
            </div>
          ))}
        </m.p>
        <m.div
          initial={{ translateX: '-70%', opacity: 0 }}
          whileInView={{ translateX: '-50%', opacity: 1 }}
          viewport={{ once: true }}
          transition={{
            duration: 1,
            delay: 1,
            type: 'spring'
          }}
          className="absolute left-0 top-[63%] z-10 hidden sm:block"
        >
          <CloudLeftSVG className="w-[7.71vw]" />
        </m.div>
        <m.div
          initial={{ translateX: '70%', opacity: 0 }}
          whileInView={{ translateX: '40%', opacity: 1 }}
          viewport={{ once: true }}
          transition={{
            duration: 1,
            delay: 1,
            type: 'spring'
          }}
          className="absolute right-0 top-[24%] z-10 hidden sm:block"
        >
          <CloudRightSVG className="w-[5.63vw]" />
        </m.div>
      </section>
    </BlockLayout>
  );
}
