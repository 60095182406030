import cn from 'classnames';
import { m } from 'framer-motion';
import React from 'react';

import about from '@/assets/images/home/about.png';
import aboutSplash from '@/assets/images/home/about_splash.png';
import CloudLeftSVG from '@/assets/images/home/cloud_big_left.inline.svg';
import CloudRightSVG from '@/assets/images/home/cloud_small_right.inline.svg';
import { BlockLayout } from '@/components/common/BlockLayout';
interface SectionAboutProps {
  className?: string;
}

export function SectionAbout(props: SectionAboutProps) {
  const { className } = props;

  return (
    <BlockLayout type="about">
      <section className={cn(className, 'overflow-hidden')}>
        <m.p
          initial={{ translateY: '40px', opacity: 0 }}
          whileInView={{ translateY: 0, opacity: 1 }}
          viewport={{ once: true }}
          transition={{
            duration: 1,
            delay: 0.5,
            type: 'spring'
          }}
          className="mt-[60px] mb-6 px-[3.33%] text-center text-sm leading-[1.5] sm:mb-[46px] sm:mt-[123px] sm:px-[8%] sm:text-lg lg:px-[13%] 3xl:text-xl"
        >
          CKS Crypto Bootcamp Tokyo is an event dedicated to cultivating Web3
          talents, designed to gather developers and builders from Japan.
          <br />
          CKS aims to provide a platform for young Web3 enthusiasts to receive
          guidance from industry experts and engage in in-depth discussions
          about the future of blockchain.
        </m.p>
        <m.img
          initial={{ translateY: '60px', opacity: 0 }}
          whileInView={{ translateY: 0, opacity: 1 }}
          viewport={{ once: true }}
          transition={{
            duration: 1,
            delay: 1,
            type: 'spring'
          }}
          src={about}
          className="mx-auto aspect-[2.65] w-[64.17%] min-w-[300px]"
        />
        <m.img
          initial={{ translateY: '20px', opacity: 0 }}
          whileInView={{ translateY: 0, opacity: 1 }}
          viewport={{ once: true }}
          transition={{
            duration: 1,
            delay: 1.5,
            type: 'spring'
          }}
          src={aboutSplash}
          className="-mt-[8%] w-full"
        />
        <m.div
          initial={{ translateX: '-70%', opacity: 0 }}
          whileInView={{ translateX: '-50%', opacity: 1 }}
          viewport={{ once: true }}
          transition={{
            duration: 1,
            delay: 2,
            type: 'spring'
          }}
          className="absolute left-0 top-[23.55%] z-10 hidden sm:block"
        >
          <CloudLeftSVG className="w-[7.71vw]" />
        </m.div>
        <m.div
          initial={{ translateX: '70%', opacity: 0 }}
          whileInView={{ translateX: '40%', opacity: 1 }}
          viewport={{ once: true }}
          transition={{
            duration: 1,
            delay: 2,
            type: 'spring'
          }}
          className="absolute right-0 bottom-[12.65%] z-10 hidden sm:block"
        >
          <CloudRightSVG className="w-[5.63vw]" />
        </m.div>
      </section>
    </BlockLayout>
  );
}
