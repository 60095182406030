import React, { ReactNode } from 'react';

import { useSiteMetadata } from '@/utils/hooks';

interface SeoProps {
  title?: string;
  description?: string;
  children?: ReactNode;
  url?: string;
}

export function Seo(props: SeoProps) {
  const { title, description, children, url = '' } = props;

  const {
    title: defaultTitle,
    description: defaultDescription,
    image,
    siteUrl,
    twitterUsername,
    twitterSummaryLargeImage
  } = useSiteMetadata();

  const seo = {
    title: title ? `${title} | ${defaultTitle}` : defaultTitle,
    description: description || defaultDescription,
    image: `${siteUrl}${image}`,
    url: `${siteUrl}${url}`,
    twitterUsername,
    summaryLargeImage: `${siteUrl}${twitterSummaryLargeImage}`
  };

  return (
    <>
      <title>{seo.title}</title>
      <meta name="description" content={seo.description} />
      <meta name="image" content={seo.image} />
      <meta name="og:title" content={seo.title} />
      <meta name="og:description" content={seo.description} />
      <meta name="twitter:card" content={seo.summaryLargeImage} />
      <meta name="twitter:title" content={seo.title} />
      <meta name="twitter:url" content={seo.url} />
      <meta name="twitter:description" content={seo.description} />
      <meta name="twitter:image" content={seo.image} />
      <meta name="twitter:creator" content={seo.twitterUsername} />
      {children}
    </>
  );
}
