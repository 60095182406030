import cn from 'classnames';
import { m } from 'framer-motion';
import React from 'react';

import blockImg from '@/assets/images/home/media/blockbeats.png';
import coinpostImg from '@/assets/images/home/media/coinpost.png';
import coinTelImg from '@/assets/images/home/media/cointelegraph.png';
import forImg from '@/assets/images/home/media/foreight.png';
import newsImg from '@/assets/images/home/media/news.png';
import techImg from '@/assets/images/home/media/techflow.png';
import techHubImg from '@/assets/images/home/media/techub.png';
import { BlockLayout } from '@/components/common/BlockLayout';
const supports = [
  {
    img: coinpostImg,
    alt: 'coinpost'
  },
  {
    img: coinTelImg,
    alt: 'cointelegraph'
  },
  {
    img: blockImg,
    alt: 'coinpost'
  },
  {
    img: newsImg,
    alt: 'coinpost'
  },
  {
    img: techImg,
    alt: 'coinpost'
  },
  {
    img: forImg,
    alt: 'coinpost'
  },
  {
    img: techHubImg,
    alt: 'coinpost'
  }
];

interface SectionSupportProps {
  className?: string;
}

export function SectionMedia(props: SectionSupportProps) {
  const { className } = props;

  return (
    <BlockLayout type="media" className="mt-20 sm:mt-40">
      <section className={cn(className)}>
        <m.p
          initial={{ translateY: '20px', opacity: 0 }}
          whileInView={{ translateY: 0, opacity: 1 }}
          viewport={{ once: true }}
          transition={{
            duration: 1,
            delay: 0.5,
            type: 'spring'
          }}
          className="mt-[60px] mb-6 grid grid-cols-1 gap-5 px-[15%] sm:mb-[46px] sm:mt-[123px] sm:grid-cols-2 sm:px-[4%] lg:grid-cols-3 xl:grid-cols-4"
        >
          {supports.map((support) => (
            <img src={support.img} alt={support.alt} key={support.alt} />
          ))}
        </m.p>
      </section>
    </BlockLayout>
  );
}
