import cn from 'classnames';
import React, { ReactNode } from 'react';

import SectionOneBg from '@/assets/images/common/SectionOnebg.png';

import { Footer } from './Footer';
import { Header } from './Header';

interface LayoutProps {
  [key: string]: unknown;
  className?: string;
  children: ReactNode;
}

export function Layout(props: LayoutProps) {
  const { className, children } = props;

  return (
    <div
      className={cn(
        className,
        `min-h-screen bg-[url('@/assets/images/common/SectionOnebg.png')]  sm:bg-[url('@/assets/images/common/SectionOnePcbg.png')] `
      )}
    >
      <Header />
      {children}
      {/* <Footer /> */}
    </div>
  );
}
