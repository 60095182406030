import cn from 'classnames';
import { m } from 'framer-motion';
import React, { useMemo, useState } from 'react';

import flower from '@/assets/images/common/flower.png';
import textLogo from '@/assets/images/common/text_logo.png';
import xFollowT from '@/assets/images/common/xfollowTwitter.png';
import CloundBgTrans from '@/assets/images/home/clound_bg_trans.inline.svg';
import LeftMountainSVG from '@/assets/images/home/left_mountain.inline.svg';
import leftSakura from '@/assets/images/home/left_sakura.png';
import leftSplash from '@/assets/images/home/left_splash.png';
import LocationIConSVG from '@/assets/images/home/locationIcon.inline.svg';
import mobileContent from '@/assets/images/home/mobile_content.png';
import pcContent from '@/assets/images/home/pc_content.png';
import RightMountainSVG from '@/assets/images/home/right_mountain.inline.svg';
import rightSakura from '@/assets/images/home/right_sakura.png';
import rightSplash from '@/assets/images/home/right_splash.png';
import SeaBgTrans from '@/assets/images/home/sea_bg_trans.inline.svg';
import ShintoSVG from '@/assets/images/home/shinto.inline.svg';
import ImgA from '@/assets/images/home/summarize/a.png';
import ImgAk from '@/assets/images/home/summarize/akindo.png';
import ImgAstra from '@/assets/images/home/summarize/astar.png';
import ImgBackpack from '@/assets/images/home/summarize/backpack.png';
import ImgBitbank from '@/assets/images/home/summarize/bitbank.png';
import ImgBnb from '@/assets/images/home/summarize/bnb.png';
import ImgBu from '@/assets/images/home/summarize/bu.png';
import ImgChainide from '@/assets/images/home/summarize/chainide.png';
import ImgIn from '@/assets/images/home/summarize/finite.png';
import ImgIvs from '@/assets/images/home/summarize/ivs.png';
import ImgKekkai from '@/assets/images/home/summarize/kekkai.png';
import ImgMask from '@/assets/images/home/summarize/mask.png';
import ImgMo from '@/assets/images/home/summarize/mochi.png';
import ImgOneKey from '@/assets/images/home/summarize/onekey.png';
import ImgSbi from '@/assets/images/home/summarize/sbi.png';
import ImgSolana from '@/assets/images/home/summarize/solana.png';
import ImgSoulink from '@/assets/images/home/summarize/soulink.png';
import Imgspring from '@/assets/images/home/summarize/spring.png';
import ImgTerminal from '@/assets/images/home/summarize/terminal.png';
import ImgWe from '@/assets/images/home/summarize/we.png';
import TimeHeadSVG from '@/assets/images/home/timeHead.inline.svg';
import VideoIconSVG from '@/assets/images/home/videoIcon.inline.svg';
import wubaImg from '@/assets/images/home/wuba.png';
import { Agenta } from '@/components/common/Agenta';
import {
  Dialog,
  DialogContent,
  DialogTrigger
} from '@/components/common/Dialog';
import { Video } from '@/components/common/video';

interface SectionOneProps {
  className?: string;
}

const animationStep = 0.5;
const commListImg = [
  { path: ImgChainide },
  { path: ImgKekkai },
  { path: ImgSoulink }
];
const sponListImg = [{ path: ImgSolana }, { path: ImgBackpack }];
const supportListImg = [
  { path: ImgBnb },
  { path: ImgAstra },
  { path: ImgBitbank },
  { path: ImgSbi },
  { path: ImgAk },
  { path: ImgA },
  { path: ImgMask },
  { path: ImgIn },
  { path: ImgIvs },
  { path: ImgOneKey },
  { path: ImgTerminal },
  { path: ImgBu },
  { path: ImgMo },
  { path: Imgspring }
];
export function SectionOne(props: SectionOneProps) {
  const { className } = props;
  const [videoClass, setVideoClass] = useState('');
  const handleVideoClick = () => {
    setVideoClass('sm:bottom-[72px] sm:right-[-42px]');
  };
  return (
    <>
      <section
        className={cn(
          className,
          'relative hidden h-[calc(100vh_-_64px)]  sm:block  '
        )}
      >
        <div className='absolute inset-x-0 bottom-[30px] h-[6vh] bg-[url("/pattern.png")] bg-contain bg-repeat-x sm:bottom-0  sm:h-[6vh]'></div>
        <m.div
          initial={{
            opacity: 0,
            scale: 0.3
          }}
          animate={{ opacity: 1, scale: 1 }}
          transition={{
            type: 'spring',
            duration: 2,
            delay: animationStep * 3
          }}
          className="4xl:w-[1300px]  flex flex-col  items-center justify-center px-4"
        >
          <img
            src={textLogo}
            className="drag-none w-full max-w-[184px] select-none sm:mt-[10px] sm:max-w-[18vw]"
          />
          <img src={wubaImg} className="mt-4 w-[8vw] min-w-[100px]" />
          <Agenta />
          <div className="4xl:w-[1638px] flex w-[1300px] justify-between  text-[16px]  font-[500] leading-[24px] text-[#081C1F] lg:w-[1800px] 2xl:w-[1838px] 3xl:w-[1638px]  ">
            <div>
              <div className="mb-[10px] text-[20px] font-[500] capitalize">
                commmunity organizing committee
              </div>
              <div className="flex gap-[40px]">
                {commListImg.map((item) => {
                  return (
                    <img
                      className="h-[48px] w-[160px]"
                      src={item.path}
                      key={item.path}
                    ></img>
                  );
                })}
              </div>
            </div>
            <div>
              <div className="mb-[10px] text-[20px] font-[500] capitalize">
                title sponsor
              </div>
              <div className=" flex gap-x-[90px]">
                {sponListImg.map((item) => {
                  return (
                    <img
                      className="h-[36px] w-[160px]"
                      src={item.path}
                      key={item.path}
                    ></img>
                  );
                })}
              </div>
            </div>
          </div>
          <div className="4xl:w-[1638px] mt-[30px] w-[1300px] 2xl:w-[1838px] 3xl:w-[1638px]">
            <div className="mb-[10px] text-[20px] font-[500] capitalize text-[black]">
              community support
            </div>
            <div className="flex flex-shrink gap-x-[30px]">
              {supportListImg.map((item) => {
                return (
                  <img
                    className="h-[24px] w-[109px]"
                    src={item.path}
                    key={item.path}
                  ></img>
                );
              })}
            </div>
          </div>
        </m.div>
      </section>
      <section
        className={cn(
          className,
          'relative flex h-[calc(100vh_-_64px)] items-center justify-center overflow-hidden  sm:hidden sm:h-[calc(100vh_-_76px)]'
        )}
      >
        {/* CloundBgTrans */}
        <m.div
          initial={{
            opacity: 0,
            translateY: '10%'
          }}
          animate={{ opacity: 0.7, translateY: '0' }}
          transition={{
            type: 'spring',
            duration: 2,
            delay: animationStep * 0
          }}
          className="absolute  bottom-[-20vh] hidden h-[100vh] w-[100vw] sm:block"
        >
          <CloundBgTrans className="drag-none h-full w-[100vw] select-none" />
        </m.div>

        <m.div
          initial={{
            opacity: 0,
            translateY: '10%'
          }}
          animate={{ opacity: 1, translateY: '0' }}
          transition={{
            type: 'spring',
            duration: 2,
            delay: animationStep * 1
          }}
          className="absolute  left-[-1%] bottom-[-19vh] hidden h-[60vh] sm:bottom-[7vh] sm:block sm:w-[35vw]"
        >
          <LeftMountainSVG className="drag-none h-[30vh] w-auto select-none sm:h-full" />
        </m.div>

        <m.div
          initial={{
            opacity: 0,
            translateY: '10%',
            translateX: '26%'
          }}
          animate={{ opacity: 1, translateY: '0', translateX: '26%' }}
          transition={{
            type: 'spring',
            duration: 2,
            delay: animationStep * 2
          }}
          className="absolute bottom-[0px] right-[2vw] hidden  sm:right-0 sm:bottom-[-17vh] sm:block sm:h-[83vh]"
        >
          <RightMountainSVG className="drag-none h-[40vh] w-auto select-none sm:h-full" />
        </m.div>
        <m.div
          initial={{
            opacity: 0,
            translateY: '10%'
          }}
          animate={{ opacity: 0.5, translateY: '0' }}
          transition={{
            type: 'spring',
            duration: 2,
            delay: animationStep * 0
          }}
          className="absolute  bottom-[-0vh] z-0 hidden h-[36vh] w-[100vw] sm:block"
        >
          <SeaBgTrans className="drag-none h-full w-[100vw] select-none" />
        </m.div>
        <m.img
          src={leftSplash}
          initial={{
            opacity: 0,
            translateX: '-26%'
          }}
          animate={{ opacity: 1, translateX: '0' }}
          transition={{
            type: 'spring',
            duration: 2,
            delay: animationStep * 0
          }}
          className="drag-none absolute -left-[2vw] bottom-[0vh] w-[34vw] min-w-[280px] select-none sm:min-w-[450px]"
        />
        <m.div
          initial={{
            opacity: 0,
            translateX: '26%'
          }}
          animate={{ opacity: 1, translateX: '0' }}
          transition={{
            type: 'spring',
            duration: 2,
            delay: animationStep * 1
          }}
          className="absolute bottom-[7vh] right-[15vw] h-[100px] sm:bottom-[3vh] sm:right-[10vw] sm:h-[30vh]"
        >
          <ShintoSVG className="drag-none h-full w-auto select-none" />
        </m.div>
        <m.img
          initial={{
            opacity: 0,
            translateX: '26%'
          }}
          animate={{ opacity: 1, translateX: '0' }}
          transition={{
            type: 'spring',
            duration: 2,
            delay: animationStep * 1
          }}
          src={rightSplash}
          className="drag-none absolute right-[0vw] bottom-[5vh] w-[25vw] min-w-[180px] select-none sm:bottom-[2vh] sm:-right-[3vw] sm:min-w-[280px]"
        />

        <m.img
          initial={{
            opacity: 0,
            translateX: '-100%',
            translateY: '-50%'
          }}
          animate={{ opacity: 1, translateX: '-31%', translateY: '-50%' }}
          transition={{
            type: 'spring',
            duration: 2,
            delay: animationStep * 3
          }}
          src={leftSakura}
          className="drag-none absolute left-0 top-7 z-10 hidden w-[85%] select-none sm:block sm:w-[36%]"
        />
        <m.img
          initial={{
            opacity: 0,
            translateX: '100%',
            translateY: '-44%'
          }}
          animate={{ opacity: 1, translateX: '28%', translateY: '-44%' }}
          transition={{
            type: 'spring',
            duration: 2,
            delay: animationStep * 3
          }}
          src={rightSakura}
          className="drag-none absolute right-0 top-0 hidden w-[45%] select-none sm:block sm:w-[36%]"
        />
        {/* bottom */}
        <div className='absolute inset-x-0 bottom-[-30px] h-[6vh] bg-[url("/pattern.png")] bg-contain bg-repeat-x sm:bottom-0 sm:h-[7vh] sm:h-[9.7vh]'></div>

        {/* content */}
        <m.div
          initial={{
            opacity: 0,
            scale: 0.5
          }}
          animate={{ opacity: 1, scale: 1 }}
          transition={{
            type: 'spring',
            duration: 2,
            delay: animationStep * 3
          }}
          className="flex flex-col items-center justify-center px-4"
        >
          <img
            src={textLogo}
            className="drag-none w-full max-w-[184px] select-none sm:-mt-10 sm:max-w-[20vw]"
          />
          <img src={wubaImg} className="mt-4 w-[8vw] min-w-[100px]" />
          <div className="relative left-[-20px] mt-[15px] h-[150px]   w-[375px] bg-opacity-[0.15]  bg-[linear-gradient(90deg,rgba(105,159,208,0.00)_0%,rgba(93,140,183,0.15)_21.35%,rgba(54,124,188,0.15)_83.85%,rgba(98,152,202,0.00)_100%)] sm:left-[-58px] sm:mt-[20px] sm:h-[191px] sm:w-[1050px] ">
            <div className="absolute right-[45%] z-[10] py-[12px] sm:right-[44%] sm:py-[10px]">
              <div className="absolute right-0 h-[110px] w-[2px] bg-black sm:right-[50%] sm:h-[132px] sm:w-[3px]"></div>
              <TimeHeadSVG className="absolute right-[-7px] top-[120px] h-[6] w-[10] sm:right-[-6px] sm:top-[134px] sm:h-[19] sm:w-[16]" />
            </div>
            <div className="relative z-[12] ">
              <div className="mt-[12px] flex justify-end gap-x-[9.6px] sm:mt-[10px] sm:items-center sm:justify-end sm:gap-x-[16px]">
                <span className="h-[28px] w-[148px] text-right text-[12px] font-[500] capitalize leading-[12.4px] text-[#000] sm:h-auto sm:w-auto sm:text-[20px] sm:leading-[24px]">
                  Blockchain Development Bootcamp
                </span>
                <span className="inline-flex h-[24px] items-center justify-center rounded-[6px] bg-[#000] p-[6px]  text-[9.6px] capitalize leading-[12px] text-[#FFF] sm:h-[33px] sm:gap-x-[10px] sm:rounded-[10px] sm:py-[8px] sm:px-[10px] sm:text-[16px] sm:font-[700] sm:leading-[19.2px]">
                  online
                </span>
                <div className="flex  w-[140px] flex-col text-[12px] font-[500] leading-[14.4px] text-[#000]  sm:w-auto sm:flex-row sm:text-[20px] sm:leading-[24px]">
                  <span>Jan. 26, 2024</span>{' '}
                  <span className="text-[10px] sm:text-[20px] ">
                    (10:00-18:15 「JST,UTC+9」)
                  </span>
                </div>
              </div>
              {/* <div className="flex items-center justify-center">
            <div className=" relative right-[-17px] h-[12px]  w-[1px] bg-[#000] sm:right-[-57px] sm:h-[10px] sm:w-[3px]"></div>
          </div> */}
              <div className=" mt-[10px] flex items-center justify-end gap-x-[9.6px] sm:mt-[10px] sm:gap-x-[16px]">
                <span className="h-[28px] w-[148px] text-right text-[12px] font-[500] capitalize leading-[12.4px] text-[#000] sm:h-auto sm:w-auto sm:text-[20px] sm:leading-[24px]">
                  Blockchain Business Bootcamp
                </span>
                <span className="inline-flex h-[24px] items-center justify-center rounded-[6px] bg-[#8E0022]  p-[6px] text-[9.6px] capitalize leading-[12px] text-[#FFF] sm:h-[33px] sm:gap-x-[10px] sm:rounded-[10px] sm:py-[8px] sm:px-[10px] sm:text-[16px] sm:font-[700] sm:leading-[19.2px]">
                  offline
                </span>
                <div className="flex w-[140px]  flex-col text-[12px]  font-[500] leading-[14.4px] text-[#000] sm:w-auto sm:flex-row sm:text-[20px] sm:leading-[24px]">
                  <span>Jan. 27, 2024</span>{' '}
                  <span className="text-[10px]  sm:text-[20px]">
                    (10:00-16:45「JST,UTC+9」)
                  </span>
                </div>
              </div>
              {/* <div className="flex items-center justify-center">
            <div className=" relative right-[-17px] h-[12px]  w-[1px] bg-[#000] sm:right-[-57px] sm:h-[10px] sm:w-[3px]"></div>
          </div> */}
              <div className=" mt-[10px] flex items-center justify-end gap-x-[9.6px] sm:mt-[10px]  sm:gap-x-[16px]">
                <span className="h-[28px] w-[148px] text-right text-[12px] font-[500] capitalize leading-[12.4px] text-[#000] sm:h-auto sm:w-auto sm:text-[20px] sm:leading-[24px]">
                  demo day
                </span>
                <span className="inline-flex h-[24px] items-center justify-center rounded-[6px] bg-[#000]  p-[6px] text-[9.6px] capitalize leading-[12px] text-[#FFF] sm:h-[33px] sm:gap-x-[10px] sm:rounded-[10px] sm:py-[8px] sm:px-[10px] sm:text-[16px] sm:font-[700] sm:leading-[19.2px]">
                  online
                </span>
                <div className="flex w-[139px]  flex-col text-[12px]  font-[500] leading-[14.4px] text-[#000] sm:w-auto sm:flex-row sm:text-[20px] sm:leading-[24px]">
                  <span>Jan. 28, 2024</span>{' '}
                  <span className="text-[10px]  sm:text-[20px]">
                    (10:00-17:30「JST,UTC+9」)
                  </span>
                </div>
              </div>
              {/* <div className="flex items-center justify-center align-middle">
            <div className=" relative right-[-22px] h-[5px] w-[1px] bg-[#000] sm:right-[-65px] sm:top-[0] sm:h-[5px] sm:w-[3px]"></div>
            <TimeHeadSVG className="relative right-[-17px] h-[6] w-[10] sm:right-[-56px] sm:top-[5px] sm:h-[19] sm:w-[16]" />
          </div> */}
              <div className="mt-[8px] flex items-center justify-center gap-x-[4.75px]  align-middle sm:mt-[10px] ">
                <LocationIConSVG className="h-[16px] w-[16px] sm:h-[24] sm:w-[26]" />
                <span className=" text-[11px] font-[500] leading-[13.2px] text-[#081C1F] sm:text-[18px] sm:leading-[21.6px]">
                  東京都新宿区高田馬場4-39-7 プライム高田馬場四丁目ビル 2F
                </span>
              </div>
            </div>
          </div>

          <div className="flex w-full flex-col items-center  justify-center gap-x-[5px] sm:flex-row sm:gap-x-[35px]">
            <button
              onClick={() => {
                window.open('https://twitter.com/CKSweb3', '_blank');
              }}
              className="z-10 mt-2 mb-2 flex h-9 w-full max-w-[264px] items-center justify-center  rounded-lg bg-[#4F7A87] px-[10px] text-base duration-200 hover:opacity-75 sm:mt-4 sm:mb-6 sm:h-12 sm:max-w-[329px] sm:text-xl 3xl:mt-[32px] 3xl:mb-[30px] 3xl:h-16 3xl:text-2xl"
            >
              <img
                src={xFollowT}
                width={40}
                className="drag-none select-none"
              />
              <span>Follow on Twitter</span>
            </button>
            <button
              onClick={() => {
                window.open('https://lu.ma/CKSCryptoBootcamp', '_blank');
              }}
              className="z-10 mt-[0px] mb-2 flex h-10 w-full max-w-[264px] items-center justify-center gap-x-[35px] rounded-lg bg-[#9B0024] px-[10px] text-base duration-200 hover:opacity-75  sm:mt-4 sm:mb-6 sm:h-12 sm:max-w-[329px] sm:text-xl 3xl:mt-[32px] 3xl:mb-[30px] 3xl:h-16 3xl:text-2xl"
            >
              <img src={flower} width={20} className="drag-none select-none" />
              <span>Register</span>
              <img src={flower} width={20} className="drag-none select-none" />
            </button>
          </div>
          <img
            src={pcContent}
            className="drag-none z-10 hidden  select-none sm:block sm:w-[540px] 3xl:w-[700px]"
          />
          <img
            src={mobileContent}
            className="drag-none z-10 block h-[231px] w-[340px] select-none sm:hidden"
          />
        </m.div>

        {/*  video */}
        <Dialog>
          <DialogTrigger asChild={true}>
            <m.div
              initial={{
                opacity: 0,
                translateX: '26%'
              }}
              animate={{ opacity: 1, translateX: '0' }}
              transition={{
                type: 'spring',
                duration: 2,
                delay: animationStep * 5
              }}
              className="fixed bottom-[10px] right-[10px] z-[101] h-[66px] w-[120px]   sm:bottom-[70px] sm:right-[-40px] sm:h-[146px] sm:w-[321px]"
            >
              <VideoIconSVG
                className={cn(
                  videoClass,
                  'drag-none  h-full w-auto select-none'
                )}
                onClick={handleVideoClick}
              />
            </m.div>
          </DialogTrigger>
          <DialogContent className="border-[0px] sm:max-w-[900px]">
            <Video />
          </DialogContent>
        </Dialog>
      </section>
    </>
  );
}
