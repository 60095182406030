import cn from 'classnames';
import { m } from 'framer-motion';
import React from 'react';

import supprtAkinDoImg from '@/assets/images/home/support/akindo.png';
import supportAstarImg from '@/assets/images/home/support/astar.png';
import supportBitbankImg from '@/assets/images/home/support/bitbank.png';
import supportBNBImg from '@/assets/images/home/support/bnb.png';
import suppertBubrigeImg from '@/assets/images/home/support/bubridge.png';
import suppertDfinityImg from '@/assets/images/home/support/dfinity.png';
import supportIVSImg from '@/assets/images/home/support/ivs.png';
import supportJCBAImg from '@/assets/images/home/support/jcba.png';
import supportMaskImg from '@/assets/images/home/support/mask.png';
import supportMochiImg from '@/assets/images/home/support/mochi.png';
import supportOneKeyImg from '@/assets/images/home/support/onekey.png';
import supportSBIImg from '@/assets/images/home/support/sbi.png';
import supportSpringXImg from '@/assets/images/home/support/springx.png';
import supportTerminalImg from '@/assets/images/home/support/terminal.png';
import supprtWecreate3Img from '@/assets/images/home/support/wecreate3.png';
import { BlockLayout } from '@/components/common/BlockLayout';

const supports = [
  {
    img: supportBNBImg,
    alt: 'bnb'
  },
  {
    img: supportAstarImg,
    alt: 'astar'
  },
  {
    img: supportBitbankImg,
    alt: 'bitbank'
  },
  {
    img: supportSBIImg,
    alt: 'sbi'
  },
  {
    img: supprtAkinDoImg,
    alt: 'akindo'
  },
  {
    img: supportJCBAImg,
    alt: 'jcba'
  },
  {
    img: supportMaskImg,
    alt: 'mask'
  },
  {
    img: suppertDfinityImg,
    alt: 'dfinity'
  },

  {
    img: supportIVSImg,
    alt: 'ivs'
  },
  {
    img: supportOneKeyImg,
    alt: 'onekey'
  },
  {
    img: supportTerminalImg,
    alt: 'terminal'
  },
  {
    img: suppertBubrigeImg,
    alt: 'bubridge'
  },
  {
    img: supportMochiImg,
    alt: 'mochi'
  },
  {
    img: supprtWecreate3Img,
    alt: 'wecreate3'
  },
  {
    img: supportSpringXImg,
    alt: 'springX'
  }
];

interface SectionSupportProps {
  className?: string;
}

export function SectionSupport(props: SectionSupportProps) {
  const { className } = props;

  return (
    <BlockLayout type="community" className="mt-20 sm:mt-40">
      <section className={cn(className)}>
        <m.p
          initial={{ translateY: '20px', opacity: 0 }}
          whileInView={{ translateY: 0, opacity: 1 }}
          viewport={{ once: true }}
          transition={{
            duration: 1,
            delay: 0.5,
            type: 'spring'
          }}
          className="mt-[60px] mb-6 grid grid-cols-1 gap-5 px-[15%] sm:mb-[46px] sm:mt-[123px] sm:grid-cols-2 sm:px-[4%] lg:grid-cols-3 xl:grid-cols-4"
        >
          {supports.map((support) => (
            <img src={support.img} alt={support.alt} key={support.alt} />
          ))}
        </m.p>
      </section>
    </BlockLayout>
  );
}
