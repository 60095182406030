import cn from 'classnames';
import { m } from 'framer-motion';
import React from 'react';

import committeeChainIDEImg from '@/assets/images/home/committee/chainide.png';
import committeeKekkaiImg from '@/assets/images/home/committee/kekkai.png';
import committeeSoulinkImg from '@/assets/images/home/committee/soulink.png';
import { BlockLayout } from '@/components/common/BlockLayout';

const committees = [
  {
    img: committeeChainIDEImg,
    alt: 'ChainIDE'
  },
  {
    img: committeeKekkaiImg,
    alt: 'KEKKAI'
  },
  {
    img: committeeSoulinkImg,
    alt: 'Soulink'
  }
];

interface SectionCommitteeProps {
  className?: string;
}

export function SectionCommittee(props: SectionCommitteeProps) {
  const { className } = props;

  return (
    <BlockLayout type="committee">
      <section className={cn(className)}>
        <m.p
          initial={{ translateY: '20px', opacity: 0 }}
          whileInView={{ translateY: 0, opacity: 1 }}
          viewport={{ once: true }}
          transition={{
            duration: 1,
            delay: 0.5,
            type: 'spring'
          }}
          className="mt-[60px] mb-6 flex flex-wrap items-center justify-center gap-5 px-[15%] sm:mb-[46px] sm:mt-[123px] sm:px-[8%] xl:gap-[50px]"
        >
          {committees.map((item) => (
            <img
              src={item.img}
              alt={item.alt}
              key={item.alt}
              className="w-full sm:max-w-[30%]"
            />
          ))}
        </m.p>
      </section>
    </BlockLayout>
  );
}
