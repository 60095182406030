import 'react-toastify/dist/ReactToastify.css';
import '@/styles/global.css';

import { domAnimation, LazyMotion } from 'framer-motion';
import React from 'react';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ToastContainer } from 'react-toastify';

import { I18nextProvider } from '@/i18n';

interface RootElementProps {
  children: JSX.Element;
}
const queryClient = new QueryClient();

export function RootElement(props: RootElementProps) {
  const { children } = props;

  return (
    <I18nextProvider>
      <QueryClientProvider client={queryClient}>
        <ToastContainer
          autoClose={5000}
          newestOnTop={false}
          closeOnClick={true}
          rtl={false}
          draggable={false}
          pauseOnHover={false}
        />
        <LazyMotion features={domAnimation}>{children}</LazyMotion>
      </QueryClientProvider>
    </I18nextProvider>
  );
}
