import cn from 'classnames';
import { m } from 'framer-motion';
import React from 'react';

import titleSponsorBackpack from '@/assets/images/home/sponsor/backpack.png';
import sponsorBifrostImg from '@/assets/images/home/sponsor/bifrost.png';
import sponsorC2EImg from '@/assets/images/home/sponsor/c2e.png';
import sponsorKanalabsImg from '@/assets/images/home/sponsor/kanalabs.png';
import sponsorNeoImg from '@/assets/images/home/sponsor/neo.png';
import sponsorOkcoinImg from '@/assets/images/home/sponsor/okcoin.png';
import sponsorPrestoImg from '@/assets/images/home/sponsor/presto.png';
import titleSponsorSolana from '@/assets/images/home/sponsor/solana.png';
import LineImg from '@/assets/images/home/sponsor/sponsortLine.png';
import sponsorSuiImg from '@/assets/images/home/sponsor/sui.png';
import { BlockLayout } from '@/components/common/BlockLayout';

const titleSponsorts = [
  {
    img: titleSponsorSolana,
    alt: 'solana foundrtion'
  },
  {
    img: titleSponsorBackpack,
    alt: 'Backpack'
  }
];

const sponsors = [
  {
    img: sponsorBifrostImg,
    alt: 'BIFROST'
  },
  {
    img: sponsorNeoImg,
    alt: 'Neo'
  },
  {
    img: sponsorKanalabsImg,
    alt: 'kanalabs'
  },
  {
    img: sponsorSuiImg,
    alt: 'sui'
  },
  {
    img: sponsorC2EImg,
    alt: 'c2e'
  },
  {
    img: sponsorOkcoinImg,
    alt: 'okcoin'
  },
  {
    img: sponsorPrestoImg,
    alt: 'Presto'
  }
];

interface SectionSponsorProps {
  className?: string;
}

export function SectionSponsor(props: SectionSponsorProps) {
  const { className } = props;

  return (
    <BlockLayout type="sponsor" className="mt-20 sm:mt-40">
      <section className={cn(className)}>
        <div className="flex items-center justify-center ">
          <div className="  mt-[100px] w-[50vw]  pb-3 text-center text-[20px] font-medium text-white sm:w-[30vw] sm:text-[28px] ">
            Title Sponsor
          </div>
        </div>
        <div className="flex items-center justify-center">
          <img src={LineImg} className="w-[200px] sm:h-[6px] sm:w-[512px]" />
        </div>

        <m.p
          initial={{ translateY: '20px', opacity: 0 }}
          whileInView={{ translateY: 0, opacity: 1 }}
          viewport={{ once: true }}
          transition={{
            duration: 1,
            delay: 0.5,
            type: 'spring'
          }}
          className="mt-[50px] mb-6 flex flex-wrap items-center justify-center gap-5 px-[15%] sm:mb-[46px] sm:mt-[50px] sm:px-[8%] xl:gap-[50px]"
        >
          {titleSponsorts.map((item) => (
            <img
              src={item.img}
              alt={item.alt}
              key={item.alt}
              className="w-full sm:max-w-[30%]"
            />
          ))}
        </m.p>
        <div className="flex items-center justify-center">
          <div className=" mt-4  w-[50vw] pb-3 text-center text-[20px] font-medium text-white sm:w-[30vw] sm:text-[28px] ">
            Partner Sponsor
          </div>
        </div>
        <div className="flex items-center justify-center">
          <img src={LineImg} className="w-[200px] sm:h-[6px] sm:w-[512px]" />
        </div>
        <m.p
          initial={{ translateY: '20px', opacity: 0 }}
          whileInView={{ translateY: 0, opacity: 1 }}
          viewport={{ once: true }}
          transition={{
            duration: 1,
            delay: 0.5,
            type: 'spring'
          }}
          className="mt-[50px] mb-6 flex flex-wrap items-center justify-center gap-5 px-[15%] sm:mb-[46px] sm:mt-[50px] sm:px-[8%] xl:gap-x-[60px] xl:gap-y-[90px]"
        >
          {sponsors.map((item) => (
            <img
              src={item.img}
              alt={item.alt}
              key={item.alt}
              // className="h-[25px] w-[92px] sm:h-[50px] sm:w-[185px] sm:max-w-[30%]"
            />
          ))}
        </m.p>
      </section>
    </BlockLayout>
  );
}
