import React, { Fragment, useState } from 'react';

export function Video() {
  const japanTimeZone = 'Asia/Tokyo';
  const options = { timeZone: japanTimeZone };

  const [curTime, setCurTime] = useState(() => {
    const japanCurrentTime = new Date().toLocaleString('en-US', options);
    return new Date(japanCurrentTime).getTime() / 1000;
  });
  const timeArr = [
    {
      title: 'CKS Crypto Bootcamp Day 1',
      // 1/26 09:00 ~ 18:15
      time: [1706194800, 1706264100],
      url: 'https://www.youtube.com/embed/nsKvsrY5zPs?si=h_xgPTFX2kfORD1C'
    },
    {
      title: 'CKS Crypto Bootcamp Day 2',
      // 1/27 09:00 ~ 17:00
      time: [1706264101, 1706342400],
      url: 'https://www.youtube.com/embed/4uqroiJUdKA?si=JgqHTN_koTu9TpCn'
    },
    {
      title: 'CKS Crypto Bootcamp Day 3',
      // 1/28 09:00 ~ 17:00
      time: [1706342401, 1706428800],
      url: 'https://www.youtube.com/embed/kKjXaDW4XoM?si=XGR78cSmkqnSSwrQ'
    }
  ];
  return (
    <div>
      {curTime > 1706428800 ? (
        <iframe
          id="first_screen_video"
          className="mx-auto mt-6 mb-10 aspect-[1.78] w-[calc(100vw_-_48px)] sm:mt-12 sm:mb-8 lg:w-[900px]"
          src="https://www.youtube.com/embed/kKjXaDW4XoM?si=XGR78cSmkqnSSwrQ"
          title={`CKS Crypto Bootcamp Day 3`}
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share;"
          allowFullScreen={true}
        ></iframe>
      ) : (
        timeArr.map((item) => {
          if (curTime >= item.time[0] && curTime <= item.time[1]) {
            return (
              <Fragment key={item.title}>
                <iframe
                  id="first_screen_video"
                  className="mx-auto mt-6 mb-10 aspect-[1.78] w-[calc(100vw_-_48px)] sm:mt-12 sm:mb-8 lg:w-[900px]"
                  src={item.url}
                  title={item.title}
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share;"
                  allowFullScreen={true}
                ></iframe>
              </Fragment>
            );
          } else {
            return null;
          }
        })
      )}
    </div>
  );
}
