import cn from 'classnames';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import YouTube, { YouTubePlayer } from 'react-youtube';

import { useWindowSize } from '@/hooks/useWindowSize';

interface ISession {
  videoTime: string;
  speaker?: string;
  title?: string;
  topic: string;
}
interface IVideo {
  date: string;
  videoId: string;
  title: string;
  sessions: ISession[];
}
const videoData: IVideo[] = [
  {
    date: 'Day 1',
    videoId: 'nsKvsrY5zPs',
    title: 'Day 1',
    sessions: [
      {
        videoTime: '00:00:00',
        topic: 'Opening Speech'
      },
      {
        videoTime: '00:12:21',
        speaker: 'Xiao Wu ',
        title: 'Founder of ChainIDE',
        topic: 'Full-stack & AI-Driven Dapp Development with ChainIDE'
      },
      {
        videoTime: '00:57:16',
        speaker: 'Dean',
        title: 'Founder of KEKKAI',
        topic: 'Why Solana?'
      },
      {
        videoTime: '03:01:04',
        speaker: 'Cheyenne Sanjita ',
        title: 'DevRel @ Sui Foundation',
        topic: 'Why Sui?'
      },
      {
        videoTime: '03:20:26',
        speaker: 'Paul Liu',
        title: 'Staff Engineer of DFINITY Foundation',
        topic: 'Full Stack Decentralization with Internet Computer Protocol'
      },
      {
        videoTime: '04:29:03',
        speaker: 'Suji',
        title: 'Founder of Mask Network',
        topic: 'My Startup Journey and the Web3 Innovation'
      },
      {
        videoTime: '04:56:00',
        speaker: 'Walter Lee',
        title: 'BNB Chain',
        topic: 'Your Web3 Future Powered by BNB Chain'
      },
      {
        videoTime: '05:45:57',
        speaker: 'William Xu ',
        title: 'Zan Engineer , ZAN.top developer',
        topic: 'Fundamental Steps of Building a Dapp'
      }
    ]
  },
  {
    date: 'Day 2',
    videoId: '4uqroiJUdKA',
    title: 'Day 2',
    sessions: [
      {
        videoTime: '00:00:43',
        speaker: 'Yuhong ',
        title: 'Leader of Astar Japan',
        topic: `日本発のグローバルブロックチェーン~日本企業のWeb3進出のベストバートナー~`
      },
      {
        videoTime: '00:25:52',
        speaker: 'Elena Kang',
        title: 'Business Strategy Director of Presto',
        topic: 'Data Research Engineering'
      },
      {
        videoTime: '00:28:49',
        speaker: 'Dohyun Pak',
        title: 'CEO of Bifrost',
        topic: 'Crypto,Blockchain and Beyond'
      },
      {
        videoTime: '00:55:16',
        speaker: '八角大輔',
        title: 'OKCoinJapan',
        topic: 'Web3ビジネスを取り卷く国内環境'
      },
      {
        videoTime: '01:23:25',
        speaker: 'Surbhi Singh',
        title: 'Co-founder of Kana Labs',
        topic: 'Kanalabs'
      },
      {
        videoTime: '01:57:26',
        speaker: 'Yijin',
        title:
          ' Founder of Terminal , Ex-Representative Director at Amber JapanTerminal',
        topic: '暗号資産経済圈のトレンド'
      },
      {
        videoTime: '02:27:47',
        speaker: 'Jonathan',
        title: 'SBI Holdings',
        topic: 'CKS CRYPTO BOOT CAMP ~ENGAGINGVCs 101~'
      }
    ]
  }
];

export function Agenta() {
  const ref = useRef<HTMLUListElement | null>(null);
  const videoRef = useRef<YouTubePlayer | null>(null);
  const [hasScroll, setHasScroll] = useState(false);
  const [hasScrollToEnd, setHasScrollToEnd] = useState(false);
  const { width } = useWindowSize();
  useEffect(() => {
    if (ref.current) {
      setHasScroll(ref.current.scrollWidth > ref.current.offsetWidth);
    }
  }, [width]);
  const [videoPlayedSeconds, setVideoPlayedSeconds] = useState(0);
  const [activeVideoId, setActiveVideoId] = useState<string>(
    videoData[0].videoId
  );
  const currentVideo = useMemo(
    () => videoData.find((item) => item.videoId === activeVideoId),
    [activeVideoId]
  );
  const [currentSession, setCurrentSession] = useState<ISession | null>(null);

  return (
    <div className="mx-auto mt-6 mb-10 flex  justify-center gap-y-5 ">
      <div className="4xl:w-[1500px] 4xl:h-[calc(1200px_/_1.78)]  mx-auto h-[calc((100vw_-_32px)_/_1.78)] w-[calc(100vw_-_32px)] bg-[#0f1c3e] lg:h-[calc(1200px_/_1.78)] lg:w-[1800px] 2xl:h-[calc(1200px_/_1.78)]  2xl:w-[1500px]  3xl:h-[calc(1200px_/_1.78)] 3xl:w-[1200px]">
        <YouTube
          iframeClassName="w-[calc(100vw_-_32px)] h-[calc((100vw_-_32px)_/_1.78)] lg:w-[1500px] lg:h-[calc(1200px_/_1.78)] 2xl:w-[1500px] 2xl:h-[calc(1200px_/_1.78)] 3xl:h-[calc(1200px_/_1.78)] 3xl:w-[1200px] 4xl:w-[1500px] 4xl:h-[calc(1200px_/_1.78)]"
          videoId={activeVideoId}
          onReady={(e) => {
            videoRef.current = e.target;
          }}
        />
      </div>
      <div className="4xl:h-[calc(1300px_/_1.78)] hidden w-[calc(100vw_-_32px)] flex-col overflow-y-hidden lg:flex lg:h-[calc(700px_/_1.78)] lg:w-[260px] 2xl:h-[calc(1200px_/_1.78)] 3xl:h-[calc(1200px_/_1.78)]  3xl:w-[438px]">
        <header
          className="4xl:h-14 4xl:text-2xl flex h-8 w-full items-center bg-[#081C1F] bg-[url('/meta/pattern.svg')] bg-contain bg-repeat pl-2 text-base font-semibold leading-[1.2]
text-[#FFFFFF] sm:h-10 lg:pl-[14px] lg:text-xl"
        >
          Agenda
        </header>
        <div className="relative flex flex-1 flex-col overflow-y-auto bg-[#E4ECEF]">
          <ul
            ref={ref}
            className="4xl:h-12 no-scrollbar flex h-8 flex-row overflow-x-auto sm:h-10"
          >
            {videoData.map((item, index) => {
              const isLast = index === videoData.length - 1;
              const isActive = activeVideoId === item.videoId;
              return (
                <li
                  key={item.videoId}
                  onClick={() => {
                    setActiveVideoId(item.videoId);
                    setTimeout(() => {
                      setCurrentSession(null);
                      setVideoPlayedSeconds(0);
                    }, 0);
                  }}
                  className={cn(
                    '4xl:text-[17px] flex h-full min-w-[50px] cursor-pointer items-center justify-center border-b-[2px] border-solid text-sm font-medium leading-[1.2] lg:min-w-[60px] lg:text-[15px] 3xl:min-w-[70px] 3xl:text-base',
                    isActive
                      ? 'border-b-[#081C1F] text-[#081C1F]'
                      : 'border-b-[#081C1F] text-[#081C1F] opacity-20 lg:border-b-[#081C1F]',
                    isLast && 'mr-[60px]'
                  )}
                >
                  {item.title}
                </li>
              );
            })}
            {hasScroll && (
              <div className="4xl:h-12 border-b-solid absolute right-0 flex h-8 w-[60px] items-center justify-center gap-x-[2px] border-b-[2px] border-b-[#081C1F] bg-[#081C1F] sm:h-10 lg:border-b-[#081C1F]">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                  className="cursor-pointer"
                  onClick={() => {
                    ref.current?.scrollTo({ left: 0, behavior: 'smooth' });
                    if (hasScrollToEnd) {
                      setHasScrollToEnd(false);
                    }
                  }}
                >
                  <rect
                    width="20"
                    height="20"
                    rx="10"
                    transform="matrix(-1 0 0 1 20 0)"
                    fill={!hasScrollToEnd ? '#081C1F' : '#081C1F'}
                    className="duration-200"
                  />
                  <path
                    d="M9.18154 10L13 6.11094L11.9092 5L7 10L11.9092 15L13 13.8891L9.18154 10Z"
                    fill="#081C1F"
                  />
                </svg>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                  className="cursor-pointer"
                  onClick={() => {
                    ref.current?.scrollTo({ left: 1000, behavior: 'smooth' });
                    if (!hasScrollToEnd) {
                      setHasScrollToEnd(true);
                    }
                  }}
                >
                  <rect
                    width="20"
                    height="20"
                    rx="10"
                    className="duration-200"
                    fill={hasScrollToEnd ? '#081C1F' : '#081C1F'}
                  />
                  <path
                    d="M10.8185 10L7 6.11094L8.09077 5L13 10L8.09077 15L7 13.8891L10.8185 10Z"
                    fill="#081C1F"
                  />
                </svg>
              </div>
            )}
          </ul>
          <div className="hidden flex-1 flex-row gap-x-3 overflow-y-auto py-5 pl-3 pr-2 lg:flex 3xl:pl-[18px]">
            <div className="relative h-fit pl-3">
              <div className="absolute left-0 -top-1 -bottom-1 w-[1px] bg-[#081C1F]"></div>
              <ul className="flex flex-col gap-y-5">
                {currentVideo?.sessions?.map((session) => {
                  const isSessionActive =
                    session.topic === currentSession?.topic;
                  return (
                    <li
                      key={session.videoTime}
                      className="relative cursor-pointer"
                      onClick={() => {
                        setCurrentSession(session);
                        videoRef.current?.seekTo(
                          getSeconds(session.videoTime),
                          true
                        );
                      }}
                    >
                      <h3
                        className={cn(
                          '4xl:text-base mb-2 text-sm font-medium leading-[1.2] duration-150 lg:text-[15px]',
                          isSessionActive ? 'text-[#CC352D]' : 'text-[#081C1F]'
                        )}
                      >
                        {session.topic}
                      </h3>
                      {session.title ? (
                        <div
                          className={cn(
                            'flex flex-row items-start gap-x-[6px] rounded bg-[#081C1F] p-[6px] opacity-40 duration-150',
                            isSessionActive ? 'opacity-100' : 'opacity-40'
                          )}
                        >
                          <div className="flex flex-col gap-y-[2px]">
                            <span className="4xl:text-sm text-xs font-semibold leading-[1.2] lg:text-[13px]">
                              {session.speaker}/{session.title}
                            </span>
                          </div>
                        </div>
                      ) : null}
                      <div
                        className={cn(
                          '4xl:w-[8px] 4xl:-left-[15px] 4xl:top-2 absolute -left-[14px] top-[6px] aspect-square w-[5px] rounded-full duration-150',
                          isSessionActive ? 'bg-[#CC352D]' : 'bg-[#081C1F]'
                        )}
                      ></div>
                    </li>
                  );
                })}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

function getSeconds(time: string) {
  const timeArray = time.split(':');
  return (
    Number(timeArray[0]) * 3600 +
    Number(timeArray[1]) * 60 +
    Number(timeArray[2])
  );
}
