import cn from 'classnames';
import React, { useEffect, useRef, useState } from 'react';

import announcement from '@/assets/images/home/schedule/announcement.png';
import award from '@/assets/images/home/schedule/award.png';
import cheers from '@/assets/images/home/schedule/cheers.png';
import coding from '@/assets/images/home/schedule/coding.png';
import coffee from '@/assets/images/home/schedule/coffee.png';
import lunch from '@/assets/images/home/schedule/lunch.png';

import { IScheduleTimeLine } from './SectionSchedule';
interface TimeLineProps {
  className?: string;
  data: IScheduleTimeLine[];
}

export function TimeLine(props: TimeLineProps) {
  const { className, data } = props;
  const ref = useRef<HTMLDivElement>(null);
  const [elementSize, setElementSize] = useState({ width: 0, height: 0 });

  useEffect(() => {
    const updateSize = () => {
      if (!ref.current) return;
      const { width, height } = ref.current.getBoundingClientRect();
      setElementSize({ width, height });
    };

    // 初次渲染时获取大小
    updateSize();

    // 在窗口大小变化时更新大小
    window.addEventListener('resize', updateSize);

    // 清除副作用
    return () => {
      window.removeEventListener('resize', updateSize);
    };
  }, [data]);

  return (
    <ul className={cn(className, 'text-[#EAFFFF]')}>
      {data.map((item, index) => {
        const isFirst = index === 0;
        const isLast = index === data.length - 1;
        return (
          <li
            key={item.time}
            className={cn(
              !isLast && 'pb-6 sm:pb-10',
              'relative flex items-start'
            )}
          >
            <span
              className={cn(
                'w-[76px] shrink-0 text-xs font-medium leading-[1.8] sm:w-[130px] sm:text-xl'
              )}
            >
              {item.time}
            </span>

            <div
              className={cn(
                'relative left-3 z-10 mt-1 mr-6 h-2 w-2 shrink-0 rounded-full bg-[#CEFFEF] sm:mt-2 sm:mr-8 3xl:left-[20px] 3xl:mr-12 3xl:h-3 3xl:w-3'
              )}
            >
              {isFirst && (
                <div className="z-1 absolute -top-6 left-1 h-6 w-[1px] bg-[linear-gradient(180deg,#498273ff_0%,#CEFFEF_100%)] 3xl:left-[6px]"></div>
              )}
              {isLast && (
                <div
                  style={{
                    height: `${elementSize.height + 10}px`
                  }}
                  className="z-1 absolute -top-2 left-1 w-[1px] bg-[linear-gradient(180deg,#CEFFEF_0%,#CEFFEF_50%,#498273ff_100%)] sm:left-[3px] 3xl:-top-3 3xl:left-[6px]"
                ></div>
              )}
            </div>

            <div
              ref={ref}
              className="flex flex-1 flex-col gap-y-[6px] sm:-mt-1"
            >
              <div className={cn('flex flex-wrap items-center gap-y-1')}>
                {item.type === 'opening' && (
                  <img src={cheers} className="mr-1 w-4 shrink-0 sm:w-5" />
                )}
                {item.type === 'award' && (
                  <img src={award} className="mr-1 w-4 shrink-0 sm:w-5" />
                )}
                {item.type === 'break' && (
                  <img src={coffee} className="mr-1 w-4 shrink-0 sm:w-5" />
                )}
                {item.type === 'lunch' && (
                  <img src={lunch} className="mr-1 w-4 shrink-0 sm:w-5" />
                )}
                {item.type === 'coding' && (
                  <img src={coding} className="mr-1 w-4 shrink-0 sm:w-5" />
                )}
                {item.type === 'announcement' && (
                  <img
                    src={announcement}
                    className="mr-1 w-4 shrink-0 sm:w-5"
                  />
                )}
                {item.type === 'course' && item.devCourse && (
                  <button
                    className="mr-2 whitespace-nowrap rounded px-1 py-2 text-xs text-[#291F04] sm:mr-4 sm:py-[14px] sm:px-8 sm:text-[20px]"
                    style={{
                      backgroundColor: item.devCourse.bgColor,
                      color: item.devCourse.color
                    }}
                  >
                    {item.devCourse.title}
                  </button>
                )}
                <span className="flex-1 text-xs font-semibold leading-[1.8] sm:text-[20px]">
                  {item.topic}
                </span>
              </div>
              {!!item.speakers?.length && (
                <div className="mt-1 rounded bg-[#73D0CF4D] px-2 py-1 text-xs text-[#EAFFFF] sm:mt-2 sm:px-4 sm:text-base">
                  {item.speakers.map((speaker, speakerIndex) => (
                    <div
                      key={speakerIndex}
                      className="flex items-center justify-center gap-x-2"
                    >
                      <div className="aspect-square w-1 rounded-full bg-[#EAFFFF]"></div>
                      <span className="flex-1 leading-[2]">
                        {speaker.name && speaker.desc && (
                          <span>
                            <span className="font-semibold">
                              {speaker.name},
                            </span>
                            &nbsp;<span>{speaker.desc}</span>
                          </span>
                        )}
                        {speaker.name && !speaker.desc && (
                          <span>
                            <span className="font-semibold">
                              {speaker.name}
                            </span>
                          </span>
                        )}
                        {!speaker.name && speaker.desc && (
                          <span>{speaker.desc}</span>
                        )}
                      </span>
                    </div>
                  ))}
                </div>
              )}
            </div>
            <div
              className={cn(
                'absolute top-0 bottom-0 left-[92px] w-[1px] bg-[#CEFFEF] sm:left-[145px] 3xl:left-[156px]',
                isFirst && '!top-2',
                isLast && '!w-0'
              )}
            ></div>
          </li>
        );
      })}
    </ul>
  );
}
